import { paths } from '../../config/paths'
import { LuArrowLeft} from 'react-icons/lu'
import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetOneTaskQuery, useGetOneTaskRegularQuery, useUpdateTaskOneMutation } from '../../slices/taskApiSlice'
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'
import {
  Container, Header, Title, BackLink,
  Content, TaskType, InfoButton,
  InfoTitle, InfoSection, UserInfo, UserAvatar,
  UserInitials, UserName, Status, Dates, Footer,
  InfoContainer,
  Label, FieldValue, EditableField, IconButton, CustomSelectMultiContainer,
  UserNameInfo,  UserAvatarInfo,
  UserInitialsInfo, ButtonFilter, RowIsInput, ContainerTaskIsInput, LabelWrapper, Row2,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from './TaskPage.style'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import { CustomFieldPermisson } from '../../enum/TaskPermisson/CustomFieldPermisson'
import { LuPlusCircle } from 'react-icons/lu'
import { Form, Formik } from 'formik'
import CustomSelectMulti from '../../ui-kit/Dropdown/CustomDropdwonOverFlowParent/TaskDropdownMulti'
import CustomSelect from '../../ui-kit/Dropdown/CustomDropdwonOverFlowParent/TaskDropdown'
import CustomSelectMulti2 from '../../ui-kit/Dropdown/TaskDropdownMulti'
import CustomSelect2 from '../../ui-kit/Dropdown/TaskDropdown'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './DatePicker.module.css'
import styleLIST from '../Tasks/Components/TaskForm/DatePickerList.module.css'
import {
    DROPDOW_OPTIONS,
    INPUT_FILED,
    DATE_OPTIONS,
    TASK_FORM_OPTIONS,
    EDITOR, LIST
} from '../../enum/Task/TaskFormPermisson'
import {
    useGetOptionValueCustomFieldsQuery, useUpdateTaskTypeStatusMutation, 
} from '../../slices/taskTypeApiSlice'
import ButtonSecondary from '../../ui-kit/Button/ui/components/ButtonSecondary'
import WangEditorTask from '../../ui-kit/Form/components/Editor/WangEditorTaskPage'
import DropdownStatus from '../../ui-kit/Dropdown/TaskOneDropdwon/DropdwonStatus'

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { v4 as uuidv4 } from 'uuid';
import Toast from '../../ui-kit/Toast/ToastTask';
import { FormikInput } from '../../ui-kit/'
import { LuTrash, LuFileEdit } from 'react-icons/lu'
import Button from '../../ui-kit/Button/Button'


export const TaskPage = () => {
    const { intl } = useIntlContext();
    const { id } = useParams();
    const { userCompany, userInfo } = useSelector((state: any) => state.auth);
    
    const { data: task, refetch: oneTaskrefetch,  error, isLoading } = useGetOneTaskQuery(
        { id, update: true }
    );

    const [updatetask] = useUpdateTaskOneMutation();
    const [updateTaskStatus] = useUpdateTaskTypeStatusMutation();

    

    useEffect(() => {
            oneTaskrefetch();  
        return () => {
            localStorage.removeItem('uuids');
            localStorage.removeItem('frontId');
        };
    }, []);

    const taskData = task?.data
    const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)'

    const [showInfo, setShowInfo] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState<any>(taskData?.status ? { value: taskData.status.id, label: taskData.status.name } : null);
    const [popUpMessage, setPopUpMessage] = useState(false);
    const [activeTab, setActiveTab] = useState('info');
    const [duplicatedListItems, setDuplicatedListItems] = useState<{ [key: number]: number[] }>({});
    const [backendListData, setBackendListData] = useState<any>([]);
    const [openPopupContent, setOpenPopupContent] = useState<any>(false);

    useEffect(() => {
        if (popUpMessage) {
          const timer = setTimeout(() => {
            setPopUpMessage(false);
          }, 3000);
      
          return () => clearTimeout(timer); 
        }
    }, [popUpMessage]);
  
    useEffect(() => {
      if (task?.data?.custom_field_list_values) { 
        setBackendListData([])
        setBackendListData(task?.data?.custom_field_list_values)
         } 
    }, [task?.data?.custom_field_list_values]);
  

    useEffect(() => {
      if (task?.data?.custom_field_list_values.length == 0) {
        const initialDuplicatedListItems: { [key: number]: number[] } = {};
        task?.data?.custom_fields?.forEach((taskType: any) => {
          if (taskType.field_type === LIST.LIST) {
            initialDuplicatedListItems[taskType.id] = [0];
          }
        });
        setDuplicatedListItems(initialDuplicatedListItems);
      }
    }, [task, oneTaskrefetch]);
        
    const initialValues = {
        content: task?.data?.content || '',
        customFields: taskData?.custom_fields?.reduce((acc: any, field: any) => {
          const entityValue = taskData?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.id)?.value
          const fieldValue = taskData?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.id)?.value

          switch (field.field_type) {
            case DROPDOW_OPTIONS.USER:
            case DROPDOW_OPTIONS.DEPARTMENT:
            case DROPDOW_OPTIONS.OFFICE:
            case DROPDOW_OPTIONS.JOB_TITLE:
            case DROPDOW_OPTIONS.PROJECT_GROUP:
                acc[field.id] = entityValue ? Array.isArray(entityValue) ? entityValue.map((val: any) => ({ value: val.id, label: val.name })) : { value: entityValue.id, label: entityValue.name } : null
              break
            case TASK_FORM_OPTIONS.DROPDOWN: 
                acc[field.id] = fieldValue ? fieldValue.map((val: any) => ({
                  value: val.id,
                  label: taskData?.custom_fields?.find((cf: any) => cf.id === field.id)?.options?.find((option: any) => option.id === val.id)?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value
                })) : []
              break
            case INPUT_FILED.STRING:
            case INPUT_FILED.NUMBER:
              acc[field.id] = fieldValue || ""
              break
            case DATE_OPTIONS.DATE:
                if (fieldValue && typeof fieldValue === 'object' && fieldValue.date) {
                acc[field.id] = fieldValue.date ||  null
                }
              break
            case EDITOR.EDITOR:
              acc[field.id] = fieldValue || ''
              if (field.entity_files) {
                const backUID = localStorage.getItem('uuidCustomField');
                const prevUUIDs = backUID ? JSON.parse(backUID) : [];
                const newUUIDs = field.entity_files.map((file: any) => file.uuid);
                const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
                localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
              }
                  break
              case LIST.LIST:
                        acc[field.id] = field.list_items.reduce((listAcc: any, listItem: any) => { 
                          task?.data?.custom_field_list_values?.forEach((dataGroup: any) => {
                            dataGroup.data.forEach((listValue: any) => {
                              if (listValue.custom_field_id === listItem.id) {
                                switch (listItem.field_type) {
                                  case DROPDOW_OPTIONS.USER:
                                  case DROPDOW_OPTIONS.DEPARTMENT:
                                  case DROPDOW_OPTIONS.OFFICE:
                                  case DROPDOW_OPTIONS.JOB_TITLE:
                                  case DROPDOW_OPTIONS.PROJECT_GROUP:
                                    listAcc[`${listItem.id}.${dataGroup.index}`] = Array.isArray(listValue.value)
                                      ? listValue.value.map((val: any) => ({ value: val.id, label: val.name }))
                                      : listValue.value
                                      ? { value: listValue.value.id, label: listValue.value.name }
                                      : null;
                                    break;
                        
                                  case TASK_FORM_OPTIONS.DROPDOWN:
                                    listAcc[`${listItem.id}.${dataGroup.index}`] = Array.isArray(listValue.value)
                                      ? listValue.value.map((cf: any) => ({
                                          value: cf.id,
                                          label: listItem.options
                                            .find((option: any) => option.id === cf.id)
                                            ?.translations.find((translation: any) => translation.language_code === intl.locale)
                                            ?.translated_value,
                                        }))
                                      : [];
                                    break;
                        
                                  case DATE_OPTIONS.DATE:
                                    listAcc[`${listItem.id}.${dataGroup.index}`] = listValue.value?.date || null;
                                    break;
                        
                                  case INPUT_FILED.STRING:
                                  case INPUT_FILED.NUMBER:
                                    listAcc[`${listItem.id}.${dataGroup.index}`] = listValue.value || "";
                                    break;
                        
                                  default:
                                    console.warn("Unhandled field type:", listItem.field_type);
                                    break;
                                }
                              }
                            });
                          });
                          return listAcc;
                        }, {});
                        break;
                        
            default:
              break
          }
          return acc
        }, {}) || {}
    };

  useEffect(() => {
    if (task?.data?.entity_files) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = task.data.entity_files.map((file: any) => file.uuid);
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs))
    }
  }, [task?.data?.entity_files]);

  useEffect(() => {
    if (task?.data?.custom_field_values?.some((field: any) => field.entity_files)) {
      const backUID = localStorage.getItem('uuidCustomField');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = task.data.custom_field_values
        .filter((field: any) => field.entity_files)
        .flatMap((field: any) => field.entity_files.map((file: any) => file.uuid));
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
    }
  }, [task?.data?.custom_field_values]);
    

    const mapOptions = (optionsData: any) => optionsData?.data?.map((option: any) => ({
        value: option.id,
        label: option.name || option.fullname,
    }));

  const { data: userOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.USER },
    {
      skip: !task?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.USER || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.USER))
      )
    }
  )
  
  const { data: deparmentOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.DEPARTMENT },
    {
      skip: !task?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.DEPARTMENT || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT))
      )
    }
  )
  
  const { data: officeOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.OFFICE },
    {
      skip: !task?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.OFFICE || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.OFFICE))
      )
    }
  )
  
  const { data: jobTitleOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.JOB_TITLE },
    {
      skip: !task?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.JOB_TITLE || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE))
      )
    }
  )
  
  const { data: projectGroupOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.PROJECT_GROUP },
    {
      skip: !task?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.PROJECT_GROUP || 
        (field.field_type === LIST.LIST && field.list_items.some((listItem: any) => listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP))
      )
    }
  )

    const optionsUser = mapOptions(userOptions);
    const optionsDepartment = mapOptions(deparmentOptions);
    const optionsOffice = mapOptions(officeOptions);
    const optionsJobTitle = mapOptions(jobTitleOptions);
    const optionsProjectGroup = mapOptions(projectGroupOptions);
    const mapOptionsStatusEdit = (optionsData: any, selectedStatusId: any) => {
        const selectedStatus = optionsData?.find((option: any) => option.id === selectedStatusId);
        if (!selectedStatus) return [];
      
        const previousStatuses = new Map();
        const nextStatuses = new Map();
        const uniqueNames = new Set();
      
        const addStatuses = (statuses: any[], map: Map<any, any>) => {
          statuses.forEach((status: any) => {
            if (!uniqueNames.has(status.name)) {
              uniqueNames.add(status.name);
              map.set(status.id, { value: status.id, label: status.name, bg_color: status.bg_color, font_color: status.font_color });
            }
          });
        };
      
        addStatuses(selectedStatus.previous_statuses || [], previousStatuses);
        addStatuses(selectedStatus.next_statuses || [], nextStatuses);
      
        if (!uniqueNames.has(selectedStatus.name)) {
          uniqueNames.add(selectedStatus.name);
        }
      
        const combinedStatuses = [
          ...Array.from(previousStatuses.values()),
          { value: selectedStatus.id, label: selectedStatus.name, bg_color: selectedStatus.bg_color, font_color: selectedStatus.font_color },
          ...Array.from(nextStatuses.values())
        ];
      
        return combinedStatuses.filter((status, index, self) =>
          index === self.findIndex((s) => s.label === status.label)
        );
      };
    
    useEffect(() => {
        if (taskData?.status?.id) {
            setSelectedStatus({ value: taskData.status.id, label: taskData.status.name });
        }
    }, [taskData?.status?.id]);
    
    const optionsStatusEdit = useMemo(() => mapOptionsStatusEdit(taskData?.custom_field_statuses, taskData?.status?.id), [taskData?.custom_field_statuses, taskData?.status?.id]);
    
  const dropdownOptions = useMemo(() => {
    return task?.data?.custom_fields?.reduce((acc: any, field: any) => {
      if (field.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
        acc[field.id] = field.options?.map((option: any) => {
          const translatedValue = option.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || ''
          return { ...option, option_value: translatedValue }
        }) || []
      }
      if (field.field_type === LIST.LIST) {
        field.list_items.forEach((listItem: any) => {
          if (listItem.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
            acc[listItem.id] = listItem.options?.map((option: any) => {
              const translatedValue = option.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || ''
              return { ...option, option_value: translatedValue }
            }) || []
          }
        })
      }
      return acc
    }, {}) || {}
  }, [task, intl.locale])

  const mapOptionsDropdown = (optionsData: any) => optionsData?.map((option: any) => ({
    value: option.id,
    label: option.option_value,
    field_id: option.field_id
  }))
    
    
    if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
    if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTask', defaultMessage: 'Error while loading Task' })}</p></div>;


    async function handleStatusChange(id: any) {
        try {
            await updateTaskStatus({ id: taskData.id, status_id: { status_id: id } }).unwrap();
            oneTaskrefetch()
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = async (values: any) => {
        const customFieldValues: any = []
        const customFieldEntityValues: any = []
        const custom_field_list_values: any = []
        const customUID = localStorage.getItem('uuids');
        const frontUID = localStorage.getItem('frontId');
        
        Object.keys(values.customFields).forEach((key) => {
          if (!isNaN(Number(key))) {
              const taskType = taskData?.custom_fields.find((task: any) => task.id === Number(key))
              if (taskType && values.customFields[key] && values.customFields[key] !== '' && taskType.is_output) {
                  if (taskType.field_type === DATE_OPTIONS.DATE) {
                      customFieldValues.push({
                          custom_field_id: Number(key),
                          value: values.customFields[key]
                      })
                  } else if (taskType.field_type === DROPDOW_OPTIONS.USER ||
                      taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                      taskType.field_type === DROPDOW_OPTIONS.OFFICE ||
                      taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                      taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP) {
                      const entityValues = Array.isArray(values.customFields[key])
                          ? values.customFields[key].map((item: any) => {
                              const entityValue = task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === Number(key))?.value.find((val: any) => val.id === item.value);
                              return { id: item.value, entity_value_id: entityValue?.entity_value_id };
                          })
                          : [{
                              id: values.customFields[key]?.value,
                              entity_value_id: task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === Number(key))?.value.find((val: any) => val.id === values.customFields[key]?.value)?.entity_value_id
                          }];
                      if (entityValues.length > 0 && entityValues[0].id) {
                          customFieldEntityValues.push({
                              custom_field_id: Number(key),
                              value: entityValues
                          });
                      }
                  } else if (taskType.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
                      const dropdownValues = Array.isArray(values.customFields[key])
                          ? values.customFields[key].map((item: any) => ({ id: item.value }))
                          : [{ id: values.customFields[key]?.value }];
                      if (dropdownValues.length > 0 && dropdownValues[0].id) {
                          customFieldValues.push({
                              custom_field_id: Number(key),
                              value: dropdownValues
                          });
                      }
                  } else if (taskType.field_type === EDITOR.EDITOR) {
                      const editorField: any = {
                          custom_field_id: Number(key),
                          value: values.customFields[key]
                      };
                      if (customUID) {
                          try {
                              const parsedBackUID = JSON.parse(customUID);
                              if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                                  editorField.entity_files = {
                                      uuid: frontUID || uuidv4(),
                                      files: parsedBackUID.map((id: any) => ({
                                          id,
                                          tag: ""
                                      })),
                                  };
                              }
                          } catch (error) {
                              console.error('Failed to parse backUID:', error);
                          }
                      }
                      customFieldValues.push(editorField);
                  } else if (taskType.field_type === INPUT_FILED.STRING || taskType.field_type === INPUT_FILED.NUMBER) {
                      customFieldValues.push({
                          custom_field_id: Number(key),
                          value: values.customFields[key]
                      })
                  } else if (taskType.field_type === LIST.LIST) {
                              const listValuesMap: any = {};
                              Object.keys(values.customFields[key]).map((listItemId: any) => {
                                const listItemValue = values.customFields[key][listItemId] 
                                if (listItemId.includes('.')) {
                                  const [customFieldId, index] = listItemId.split('.');
                                  const listItem = taskType.list_items.find((listItem: any) => listItem.id === Number(customFieldId))?.field_type;
                                  const indexData = backendListData.find((dataGroup: any) => dataGroup.index === Number(index))?.data.find((item: any) => item.custom_field_id === Number(customFieldId));
                                  
                                  if (!indexData) {
                                    return;
                                  }

                                  if (!listValuesMap[Number(index)]) {
                                    listValuesMap[Number(index)] = [];
                                  }
                  
                                  if (listItem === DROPDOW_OPTIONS.USER ||
                                    listItem === DROPDOW_OPTIONS.DEPARTMENT ||
                                    listItem === DROPDOW_OPTIONS.OFFICE ||
                                    listItem === DROPDOW_OPTIONS.JOB_TITLE ||
                                    listItem === DROPDOW_OPTIONS.PROJECT_GROUP) {
                                    const value = Array.isArray(listItemValue)
                                      ? listItemValue?.map((item: any) => {
                                        const entityValue = indexData?.value.find((val: any) => val.id === item.value);
                                        return { id: item?.value, value: item?.label, parent_id: Number(key), ...(entityValue ? { entity_value_id: entityValue.entity_value_id } : { custom_field_id: Number(key) }) };
                                      })
                                      : [{
                                        id: listItemValue?.value,
                                        value: listItemValue?.label,
                                        parent_id: Number(key),
                                        ...(indexData?.value.find((val: any) => val.id === listItemValue?.value) ? { entity_value_id: indexData.value.find((val: any) => val.id === listItemValue?.value).entity_value_id } : { custom_field_id: Number(key) })
                                      }];
                                    if (value?.length > 0 && value[0]?.id) {
                                      listValuesMap[Number(index)].push({
                                        custom_field_id: Number(customFieldId),
                                        parent_id: Number(key),
                                        value: value,
                                        ...(indexData?.id && { id: indexData?.id })
                                      });
                                    }
                                  } else if (listItem === TASK_FORM_OPTIONS.DROPDOWN) {
                                    const value = Array.isArray(listItemValue)
                                      ? listItemValue?.map((item: any) => {
                                        const entityValue = indexData?.value.find((val: any) => val.id === item.value);
                                        return entityValue ? { id: item?.value } : { custom_field_id: Number(key), id: item?.value };
                                      })
                                      : [{
                                        id: listItemValue?.value,
                                        ...(indexData?.value.find((val: any) => val.id === listItemValue?.value) ? {} : { custom_field_id: Number(key) })
                                      }];
                        
                                    if (value.length > 0 && value[0].id) {
                                      listValuesMap[Number(index)].push({
                                        custom_field_id: Number(customFieldId),
                                        parent_id: Number(key),
                                          value: value,
                                          ...(indexData?.id && { id: indexData?.id })
                                      });
                                    }
                                  } else if (listItem === INPUT_FILED.STRING || listItem === INPUT_FILED.NUMBER || listItem === DATE_OPTIONS.DATE) {
                                    if (listItemValue) {
                                      listValuesMap[Number(index)].push({
                                        custom_field_id: Number(customFieldId),
                                        parent_id: Number(key),
                                        value: listItemValue,
                                        ...(indexData?.id && { id: indexData?.id })
                                      });
                                    }
                                  }
                  
                                }
                              });
                              if (listValuesMap) {
                                const listValues = Object.values(listValuesMap)
                                   custom_field_list_values.push(...listValues);
                              }
                  
                             
                            }
                          }
                        }
                      })
                    
                      Object.keys(duplicatedListItems).forEach((taskTypeId: any) => {
                        duplicatedListItems[taskTypeId].forEach((copyIndex) => {
                          const taskType = task?.data?.custom_fields.find((taskType: any) => taskType.id === Number(taskTypeId));
                          if (taskType) {
                            const listValues: any = [];
                            taskType.list_items.forEach((listItem: any) => {
                              const listItemValue = values.customFields[taskTypeId][`${listItem.id}_copy_${copyIndex}`];
                              if (listItemValue) {
                                const fildTypeOfListItem = taskType.list_items.find((item: any) => item.id === Number(listItem.id))?.field_type;
                                if (fildTypeOfListItem === DROPDOW_OPTIONS.USER ||
                                  fildTypeOfListItem === DROPDOW_OPTIONS.DEPARTMENT ||
                                  fildTypeOfListItem === DROPDOW_OPTIONS.OFFICE ||
                                  fildTypeOfListItem === DROPDOW_OPTIONS.JOB_TITLE ||
                                  fildTypeOfListItem === DROPDOW_OPTIONS.PROJECT_GROUP) {
                                  const value = Array.isArray(listItemValue)
                                    ? listItemValue?.map((item: any) => ({ id: item?.value, value: item?.label, parent_id: Number(taskTypeId), custom_field_id: Number(listItem.id) }))
                                    : [{ id: listItemValue?.value, value: listItemValue?.label, parent_id: Number(taskTypeId), custom_field_id: Number(listItem.id) }];
                                  if (value?.length > 0 && value[0]?.id) {
                                    listValues.push({
                                      custom_field_id: listItem.id,
                                      parent_id: Number(taskTypeId),
                                      value: value
                                    });
                                  }
                                } else if (fildTypeOfListItem === TASK_FORM_OPTIONS.DROPDOWN) {
                                  const value = Array.isArray(listItemValue)
                                    ? listItemValue?.map((item: any) => ({ id: item?.value, parent_id: Number(taskTypeId), custom_field_id: Number(taskTypeId) }))
                                    : [{ id: listItemValue?.value, parent_id: Number(taskTypeId), custom_field_id: Number(taskTypeId) }];
                                  if (value.length > 0 && value[0].id) {
                                    listValues.push({
                                      custom_field_id: listItem.id,
                                      parent_id: Number(taskTypeId),
                                      value: value,
                                    });
                                  }
                                } else if (fildTypeOfListItem === INPUT_FILED.STRING || fildTypeOfListItem === INPUT_FILED.NUMBER || fildTypeOfListItem === DATE_OPTIONS.DATE) {
                                  if (listItemValue) {
                                    listValues.push({
                                      custom_field_id: listItem.id,
                                      parent_id: Number(taskTypeId),
                                      value: listItemValue
                                    });
                                  }
                                }
                              }
                            });
                            if (listValues?.length > 0) {
                              custom_field_list_values.push(listValues)
                            }
                          }
                        });
                      });
  
        
        if (id) {
          customFieldValues.forEach((field: any) => {
          field.id = task?.data?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.custom_field_id)?.id
          })
          customFieldEntityValues.forEach((field: any) => {
          field.id = task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.custom_field_id)?.id
          })
        }
        try {
          let data: any = {
            content: values.content,
            creator_id: userInfo?.data?.id,
            assignee_id: taskData?.assignee?.id,
            status_id: selectedStatus?.value,
            custom_field_values: customFieldValues,
            custom_field_entity_values: customFieldEntityValues,
            custom_field_list_values: custom_field_list_values,
          }
      
          data.status = 'published'
            await updatetask({ id: id, data: data }).unwrap()
            oneTaskrefetch()
          setPopUpMessage(true)
          setDuplicatedListItems({})
          setOpenPopupContent(false)
        } catch (error) {
          console.error('Failed to create/update investigation:', error)
        }
      }
    

    const captureTaskPage = async () => {
        const input = document.getElementById('task-page');
        if (input) {
            const canvas = await html2canvas(input);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('task-page.pdf');
        }
    };



    
    

    const handleDuplicate = (taskTypeId: number) => {
      setDuplicatedListItems((prevItems) => {
          const newItems = { ...prevItems };
          if (!newItems[taskTypeId]) {
              newItems[taskTypeId] = [];
          } else {
              newItems[taskTypeId] = [...newItems[taskTypeId]];
          }
          newItems[taskTypeId].push(newItems[taskTypeId].length);
          return newItems;
      });
  };

  const handleDelete = (taskTypeId: number, index: number) => {
    setDuplicatedListItems((prevItems) => {
      const newItems = { ...prevItems };
      if (newItems[taskTypeId]) {
        newItems[taskTypeId] = newItems[taskTypeId].filter((_, i) => i !== index);
      }
      return newItems;
    });
  };

    
    return (
        <div id="task-page">
            <Container>
                <Header>
                    <Title>{taskData?.title}</Title>
                    <BackLink to={paths.tasks} color={color}>
                        <LuArrowLeft size={12} />{' '}
                        {formatMessage({
                            id: 'task.backTo',
                            defaultMessage: 'BACK TO TASK',
                        })}
                    </BackLink>
                </Header>
                <Content>
            <div style={{ width: '90%' }}>      
              <div style={{width: '90%', display: 'flex', wordBreak: 'break-all', flexDirection: 'row', flexWrap: 'wrap', gap: 10, alignItems: 'center', marginBottom: 20 }}> 
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10, width: '100%' }}>
              <Button appearance="secondary" color={userCompany?.button_color} onClick={()=>{setOpenPopupContent(true)}}><LuFileEdit/> </Button>
              <HtmlView content={taskData?.content || ''} />  
              </div>
              </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '60%', cursor: 'normal', marginBottom: 40, marginTop: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', height: '40px', justifyContent: 'space-between', border: '0.063rem solid #D2D4D7', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)' }}>
                            <ButtonFilter active={activeTab === 'info'}  style={{borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} color={userCompany?.button_color} onClick={() => setActiveTab('info')}>
                                Info
                            </ButtonFilter>
                  <ButtonFilter active={activeTab === 'worklog'}
                                style={{
                                  borderRadius: activeTab !== 'worklog' ? '0px' : undefined,
                                  borderLeft: activeTab !== 'worklog' ? '0.063rem solid #D2D4D7' : undefined,
                                  borderRight: activeTab !== 'worklog' ? '0.063rem solid #D2D4D7' : undefined
                    }}
                    color={userCompany?.button_color} onClick={() => { setActiveTab('worklog') }}>
                            {formatMessage({ id: 'task.workLog', defaultMessage: 'Worklog' })}
                        </ButtonFilter>
                        
                            <ButtonFilter active={activeTab === 'history'}   style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} color={userCompany?.button_color} onClick={() => { setActiveTab('history') }}>
                                {formatMessage({ id: 'task.history', defaultMessage: 'History' })}
                            </ButtonFilter>           
                  </div>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                        >
                            {({ values,  handleChange, setFieldValue }) => (
                  <Form>
                     {openPopupContent && ( 
                        <div
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 20,
                            zIndex: 10000
                        }}
                      >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100%', width: '80%' }}>
                    <div style={{ backgroundColor: 'white', borderRadius: 20, padding: 20, display: 'inline-block' }}>
                      <WangEditorTask name='content' />
                      <div style={{ display: 'flex', justifyContent: 'center', gap: 10, marginTop: 10, marginBottom: 10 }}>
                        <Button type='button' appearance="secondary" onClick={() => setOpenPopupContent(false)}>
                          {formatMessage({ id: 'task.cancel', defaultMessage: 'Cancel' })}
                        </Button>
                        <Button type='submit' appearance="primary">
                          {formatMessage({ id: 'task.save', defaultMessage: 'Save' })}
                        </Button>
                      </div>
                    </div>
                  </div>
                         
                        </div>
                        )} 
                    
              {activeTab === 'info' && (
                <>
                  {taskData?.custom_fields?.filter((field: any) => field.field_type !== LIST.LIST && field.field_type !== EDITOR.EDITOR).length > 0 && (
                    <>
                    <Table style={{ width: '90%' }}>
                    <Thead>
                      <Tr>
                        <Th>
                          {formatMessage({ id: 'dashboard.fieldName', defaultMessage: 'Field Name' })}
                        </Th>
                        <Th>
                          {formatMessage({ id: 'dashboard.fieldValue', defaultMessage: 'Field Value' })}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {taskData?.custom_fields?.filter((field: any) => field.field_type !== LIST.LIST && field.field_type !== EDITOR.EDITOR)?.map((field: any, index: number, array: any[]) => {
                        const translation = field.translations.find((translation: any) => translation.language_code === intl.locale);
                        const fieldName = translation ? translation.translated_name : 'N/A';
                        let customFieldValue = taskData?.custom_field_values?.find((cfv: any) => cfv.custom_field_id === field.id);

                        let displayValue = '';
                        if (customFieldValue && typeof customFieldValue.value === 'object' && customFieldValue.value !== null && field.field_type === CustomFieldPermisson.DROPDOWN) {
                          displayValue = customFieldValue.value.map((val: any) => {
                            return field.options.find((option: any) => option.id === val.id)?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || 'N/A';
                          }).join(', ');
                        } else if (customFieldValue && (field.field_type === CustomFieldPermisson.STRING || field.field_type === CustomFieldPermisson.NUMBER)) {
                          displayValue = customFieldValue?.value || 'N/A';
                        } else if (customFieldValue && field.field_type === CustomFieldPermisson.DATE) {
                          displayValue = new Date(customFieldValue?.value.date).toLocaleDateString('en-CA') || 'N/A';
                        } else {
                          const value = taskData?.custom_field_entity_values.find((cfv: any) => cfv.custom_field_id === field.id)?.value;
                          displayValue = Array.isArray(value) ? value.map((v: any) => v.name).join(', ') : value?.name || 'N/A';
                        }
                        return (
                          <Tr key={field.id}>
                            <Td style={{ width: '50%' }}>
                              <FieldValue>{fieldName || 'N/A'}</FieldValue>
                            </Td>
                            <Td>
                              <FieldValue>{displayValue || 'N/A'}</FieldValue>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </>
              )}

                  
                  {taskData?.custom_fields?.filter((field: any) => field.is_output && field.field_type === LIST.LIST).map((field: any) => {
                    return (  
                      <div key = { field.id } style = {{
                        width: '90%', borderRadius: 20, border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
                        marginBottom: 20, marginTop: 50, padding: 20
                      }}>
                        <h2 style={{ textAlign: 'left', marginBottom: '1rem', marginTop: '1rem' }}>{field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</h2>
                        <div>
                          {backendListData.map((dataGroup: any, index: number) => {
                            const validItems = dataGroup.data?.filter((listValue: any) =>
                              field.list_items.some((listItem: any) => listValue?.custom_field_id === listItem.id)
                            );

                            if (validItems.length === 0) {
                              return null;
                            }
                  
                            return (
                              <Table key={dataGroup.index} style={{ marginTop: 20 }}>
                                <Thead>
                                  <Tr>
                                    <Th>
                                      {formatMessage({ id: 'dashboard.fieldName', defaultMessage: 'Field Name' })}
                                    </Th>
                                    <Th>
                                      {formatMessage({ id: 'dashboard.fieldValue', defaultMessage: 'Field Value' })}
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {validItems.map((listValue: any) => {
                                    return field.list_items.map((listItem: any) => {
                                      if (listValue?.custom_field_id === listItem.id) {
                                        return (
                                          <Tr key={field.id}>
                                            {listItem.field_type === DROPDOW_OPTIONS.USER ||
                                              listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                                              listItem.field_type === DROPDOW_OPTIONS.OFFICE ||
                                              listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                                              listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP ? (
                                              <>
                                                <Td style={{ width: '50%' }}>
                                                  <FieldValue>{listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</FieldValue>
                                                </Td>
                                                <Td>
                                                  <FieldValue>{listValue.value.map((val: any) => val?.name).join(', ')}</FieldValue>
                                                </Td >
                                              </>
                              
                                            ) :
                                              listItem.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                                                <>
                                                  <Td style={{ width: '50%' }}>
                                                    <FieldValue>{listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</FieldValue>
                                                  </Td>
                                                  <Td>
                                                    <FieldValue>{listValue.value.map((val: any) => {
                                                      const option = listItem.options.find((option: any) => option.id === val.id);
                                                      return option?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || 'N/A';
                                                    }).join(', ')}</FieldValue>
                                                  </Td >
                                                </>
                                    
                                              ) : listItem.field_type === DATE_OPTIONS.DATE ? (
                                                <>
                                                  <Td style={{ width: '50%' }}>
                                                    <FieldValue>{listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</FieldValue>
                                                  </Td >
                                                  <Td>
                                                    <FieldValue>{new Date(listValue.value?.date).toLocaleDateString('en-CA')}</FieldValue>
                                                  </Td >
                                                </>
                                              ) : (
                                                <>
                                                  <Td style={{ width: '50%' }}>
                                                    <FieldValue>{listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</FieldValue>
                                                  </Td >
                                                  <Td>
                                                    <FieldValue>{listValue.value}</FieldValue>
                                                  </Td >
                                                </>
                                              )}
                                          </Tr>
                                        );
                                      }
                                      return null;
                                    });
                                  })}
                                </Tbody>
                              </Table>
                            );
                          }
                          )}
                            {backendListData.length == 0 && ( 
                            <h2 style={{ textAlign: 'center', color: 'lightgrey', fontWeight: 500, paddingTop: 20, paddingBottom: 35 }}>
                              {formatMessage({ id: 'task.noDataFilledIn', defaultMessage: 'No data filled in' })}
                            </h2>
                            )}
                        </div>
                      </div>
                  );
                })}
                 </>
              )}
              

              {activeTab === 'worklog' && (
                <>              
                                    <ContainerTaskIsInput style={{marginTop: 50, width: '90%'}}>
                                    {taskData?.custom_fields
                                    ?.filter((field: any) => field.is_output)
                                    .filter((field: any) => field.field_type !== EDITOR.EDITOR).filter((field: any) => field.field_type !== LIST.LIST).map((field: any, index: number, array: any[]) => {
                                            const fieldName = field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name || 'N/A';
                                            const optionsDropdown = dropdownOptions[field.id] || [];
                                            if (field.field_type === EDITOR.EDITOR) return null;
                                            

                                            return (
                                                <RowIsInput key={field.id} style={{
                                                    borderRadius: index === array.length - 1 ? 15 : 0,
                                                    borderTopLeftRadius: index === 0 ? 15 : 0,
                                                    borderTopRightRadius: index === 0 ? 15 : 0,
                                                    paddingTop: index === 0 ? 20 : 15,
                                                    paddingBottom: index === array.length - 1 ? 25 : 20,
                                                    borderBottomLeftRadius: index === array.length - 1 ? 15 : 0,
                                                    borderBottomRightRadius: index === array.length - 1 ? 15 : 0,
                                                }}>
                                                        <>
                                                            {field.field_type === INPUT_FILED.STRING || field.field_type === INPUT_FILED.NUMBER ? (
                                                             <>                    
                                                                <FieldValue>{fieldName}</FieldValue>
                                                                <EditableField
                                                                    type="text"
                                                                    name={`customFields.${field.id}`}
                                                                    value={values.customFields[field.id]}
                                                                    color={color}
                                                                    onChange={handleChange}
                                                                    placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${fieldName.toLowerCase()}...`}
                                                                />
                                                            </>
                                                            ) : field.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                                                                    field.is_multiselect ? (
                                                                    <>                    
                                                                    <FieldValue>{fieldName}</FieldValue>
                                                                    <CustomSelectMultiContainer>
                                                                            <CustomSelectMulti
                                                                            label={fieldName}     
                                                                            value={values.customFields[field.id]}
                                                                            options={optionsDropdown}
                                                                            onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                        />
                                                                    </CustomSelectMultiContainer>
                                                                    </>
                                                                    ) : (
                                                                    <>                    
                                                                        <FieldValue>{fieldName}</FieldValue>
                                                                    <CustomSelectMultiContainer>
                                                                        <CustomSelect
                                                                        label={fieldName}     
                                                                        value={values.customFields[field.id]}
                                                                        options={optionsDropdown}
                                                                        onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                        placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                                />
                                                                    </CustomSelectMultiContainer>
                                                                    </>
                                                                )
                                                                ) : field.field_type === DATE_OPTIONS.DATE ? (
                                                                <>                    
                                                                <FieldValue>{fieldName}</FieldValue>
                                                                <DatePicker
                                                                    selected={values.customFields[field.id] || undefined}
                                                                    onChange={(date) => setFieldValue(`customFields.${field.id}`, date)}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    autoComplete="off"
                                                                    placeholderText="yyyy-MM-dd"
                                                                    className={styles.datePicker}
                                                                    calendarClassName={styles.calendar}
                                                                    onFocus={(e) => e.target.style.borderColor = userCompany?.button_color}
                                                                    onBlur={(e) => e.target.style.borderColor = ''}
                                                                />
                                                                </>
                                                            ) : (
                                                                (field.field_type === DROPDOW_OPTIONS.USER || 
                                                                field.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                                                                field.field_type === DROPDOW_OPTIONS.OFFICE ||
                                                                field.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                                                                field.field_type === DROPDOW_OPTIONS.PROJECT_GROUP) && (
                                                                field.is_multiselect ? (
                                                                    <>                    
                                                                        <FieldValue>{fieldName}</FieldValue>
                                                                        <CustomSelectMultiContainer>
                                                                         <CustomSelectMulti
                                                                                label={fieldName}     
                                                                                value={values.customFields[field.id]}
                                                                                options={field.field_type === DROPDOW_OPTIONS.USER ? optionsUser : field.field_type === DROPDOW_OPTIONS.DEPARTMENT ? optionsDepartment : field.field_type === DROPDOW_OPTIONS.OFFICE ? optionsOffice : field.field_type === DROPDOW_OPTIONS.JOB_TITLE ? optionsJobTitle : optionsProjectGroup}
                                                                                onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                                placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                            />
                                                                        </CustomSelectMultiContainer>
                                                                    </>
                                                                    ) : (
                                                                    <>                    
                                                                        <FieldValue>{fieldName}</FieldValue>
                                                                        <CustomSelectMultiContainer>
                                                                            <CustomSelect
                                                                                label={fieldName}                    
                                                                                value={values.customFields[field.id]}
                                                                                options={field.field_type === DROPDOW_OPTIONS.USER ? optionsUser : field.field_type === DROPDOW_OPTIONS.DEPARTMENT ? optionsDepartment : field.field_type === DROPDOW_OPTIONS.OFFICE ? optionsOffice : field.field_type === DROPDOW_OPTIONS.JOB_TITLE ? optionsJobTitle : optionsProjectGroup}
                                                                                onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                                placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                            />
                                                                        </CustomSelectMultiContainer>
                                                                    </>
                                                                    )
                                                                )
                                                            )}
                                                        </>                                              
                                                </RowIsInput>
                                            );
                  })}
                </ContainerTaskIsInput>
                       
                {taskData?.custom_fields?.filter((field: any) => field.is_output && field.field_type === LIST.LIST).map((field: any) => {
  return (
    <div key={field.id} style={{
      width: '90%', borderRadius: 20, border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
      marginBottom: 20, marginTop: 50, padding: 20
    }}>
      <h2 style={{ textAlign: 'left', marginBottom: '1rem', marginTop: '1rem' }}>{field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</h2>
      <div>
        {backendListData.map((dataGroup: any, index: number) => {
          const validItems = dataGroup.data?.filter((listValue: any) => 
            field.list_items.some((listItem: any) => listValue?.custom_field_id === listItem.id)
          );
        
          if (validItems.length === 0) {
            return null;
          }
        
          return (
            <div key={dataGroup.index} style={{ width: '100%', borderRadius: 20, border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', marginBottom: 20, padding: 15, display: 'flex', flexWrap: 'wrap', gap: 5 }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 5, width: '100%', justifyContent: 'space-between' }}>
                <div></div>
           <IconButton type='button' onClick={() => {
                      setBackendListData((prevData: any) => prevData.filter((data: any) => data.index !== dataGroup.index));
                    }}>
                      <LuTrash color='red' size={25} />
                </IconButton>
                </div> 
              {validItems.map((listValue: any) => {
                return field.list_items.map((listItem: any) => {
                  if (listValue?.custom_field_id === listItem.id) {
                    const optionsDropdownList = mapOptionsDropdown(dropdownOptions[listItem.id]) || [];
                    return (
                      <div key={listItem.id} style={{ width: '49%' }}>
                        {listItem.field_type === INPUT_FILED.STRING || listItem.field_type === INPUT_FILED.NUMBER ? (
                          <Row2 style={{ marginTop: 10 }}>
                            <FormikInput
                              label={listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                              name={`customFields.${field.id}["${listItem.id}.${dataGroup.index}"]`}
                              placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                              autoComplete="off"
                              required={listItem.is_required}
                            />
                          </Row2>
                        ) : listItem.field_type === DROPDOW_OPTIONS.USER ||
                          listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                          listItem.field_type === DROPDOW_OPTIONS.OFFICE ||
                          listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                          listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP ? (
                            listItem.is_multiselect ? (
                              <Row2>
                                <CustomSelectMulti2
                                  height='3rem'
                                  label={
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                      {field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                      {field.is_required && <p style={{ color: userCompany?.main_color }}>*</p>}
                                    </div>
                                  }
                                  value={values.customFields[field.id]?.[`${listItem.id}.${dataGroup.index}`]}
                                  options={
                                    listItem.field_type === DROPDOW_OPTIONS.USER
                                      ? optionsUser
                                      : listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                        ? optionsDepartment
                                        : listItem.field_type === DROPDOW_OPTIONS.OFFICE
                                          ? optionsOffice
                                          : listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                            ? optionsJobTitle
                                            : listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                              && optionsProjectGroup
                                  }
                                  onChange={(selectedOption) => setFieldValue(`customFields.${field.id}["${listItem.id}.${dataGroup.index}"]`, selectedOption)}
                                  placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                />
                              </Row2>
                            ) : (
                              <Row2>
                                <CustomSelect2
                                  height='3rem'
                                  label={
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                      {field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                      {field.is_required && <p style={{ color: userCompany?.main_color }}>*</p>}
                                    </div>
                                  }
                                  value={values.customFields[field.id]?.[`${listItem.id}.${dataGroup.index}`]}
                                  options={
                                    listItem.field_type === DROPDOW_OPTIONS.USER
                                      ? optionsUser
                                      : listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                        ? optionsDepartment
                                        : listItem.field_type === DROPDOW_OPTIONS.OFFICE
                                          ? optionsOffice
                                          : listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                            ? optionsJobTitle
                                            : listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                              && optionsProjectGroup
                                  }
                                  onChange={(selectedOption) => setFieldValue(`customFields.${field.id}["${listItem.id}.${dataGroup.index}"]`, selectedOption)}
                                  placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                />
                              </Row2>
                            )
                        ) : listItem.field_type === DATE_OPTIONS.DATE ? (
                          <Row2 style={{ marginTop: 17 }}>
                            <LabelWrapper>
                              <Label>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 4, color: 'black' }}>
                                  {field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}{field.is_required && <p style={{ color: userCompany?.button_color }}>*</p>}
                                </div>
                              </Label>
                              <DatePicker
                                selected={values.customFields[field.id]?.[`${listItem.id}.${dataGroup.index}`]}
                                onChange={(date) => setFieldValue(`customFields.${field.id}["${listItem.id}.${dataGroup.index}"]`, date)}
                                dateFormat="yyyy-MM-dd"
                                autoComplete="off"
                                placeholderText="yyyy-MM-dd"
                                className={styleLIST.datePicker}
                                calendarClassName={styles.calendar}
                                withPortal
                              />
                            </LabelWrapper>
                          </Row2>
                        ) : listItem.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                          listItem.is_multiselect ? (
                            <Row2>
                              <CustomSelectMulti2
                                height='3rem'
                                label={
                                  <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                    {field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                    {field.is_required && <p style={{ color: userCompany?.main_color }}>*</p>}
                                  </div>
                                }
                                value={values.customFields[field.id]?.[`${listItem.id}.${dataGroup.index}`]}
                                options={optionsDropdownList}
                                onChange={(selectedOption) => setFieldValue(`customFields.${field.id}["${listItem.id}.${dataGroup.index}"]`, selectedOption)}
                                placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                              />
                            </Row2>
                          ) : (
                            <Row2>
                              <CustomSelect2
                                height='3rem'
                                label={
                                  <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                    {field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                    {field.is_required && <p style={{ color: userCompany?.main_color }}>*</p>}
                                  </div>
                                }
                                value={values.customFields[field.id]?.[`${listItem.id}.${dataGroup.index}`]}
                                options={optionsDropdownList}
                                onChange={(selectedOption) => setFieldValue(`customFields.${field.id}["${listItem.id}.${dataGroup.index}"]`, selectedOption)}
                                placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                              />
                            </Row2>
                          )
                        ) : null}
                      </div>
                    );
                  }
                  return null;
                });
              })}
            </div>
          );
        })}
      </div>
      {duplicatedListItems[field.id] && duplicatedListItems[field.id].map((copyIndex) => (
        <div key={`${field.id}_copy_${copyIndex}`}>
          <div style={{ width: '100%', borderRadius: 20, border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', marginBottom: 20, padding: 15, display: 'flex', flexWrap: 'wrap', gap: 5 }}>
           <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', }}>
              <div></div>
              <IconButton type='button' onClick={() => handleDelete(field.id, copyIndex)} >
                <LuTrash color='red' size={25} />
              </IconButton>
            </div>
            {field.list_items.map((listItem: any) => {
              const optionsDropdownList = mapOptionsDropdown(dropdownOptions[listItem.id]);
              return (
                <div key={`${listItem.id}_copy_${copyIndex}`} style={{ width: '49%' }}>
                  {listItem.field_type === INPUT_FILED.STRING || listItem.field_type === INPUT_FILED.NUMBER ? (
                    <Row2 style={{ marginTop: 10 }}>
                      <FormikInput
                        label={listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                        name={`customFields.${field.id}.${listItem.id}_copy_${copyIndex}`}
                        placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                        autoComplete="off"
                        required={listItem.is_required}
                      />
                    </Row2>
                  ) : listItem.field_type === DROPDOW_OPTIONS.USER ||
                    listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                    listItem.field_type === DROPDOW_OPTIONS.OFFICE ||
                    listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                    listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP ? (
                      listItem.is_multiselect ? (
                        <Row2>
                          <CustomSelectMulti2
                            height='3rem'
                            label={
                              <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                {listItem.is_required && <p style={{ color: userCompany?.button_color }}>*</p>}
                              </div>
                            }
                            value={values.customFields[field.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                            options={
                              listItem.field_type === DROPDOW_OPTIONS.USER
                                ? optionsUser
                                : listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                  ? optionsDepartment
                                  : listItem.field_type === DROPDOW_OPTIONS.OFFICE
                                    ? optionsOffice
                                    : listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                      ? optionsJobTitle
                                      : listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                        && optionsProjectGroup
                            }
                            onChange={(selectedOption) => setFieldValue(`customFields.${field.id}.${listItem.id}_copy_${copyIndex}`, selectedOption)}
                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                          />
                        </Row2>
                      ) : (
                        <Row2>
                          <CustomSelect2
                            height='3rem'
                            label={
                              <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                {listItem.is_required && <p style={{ color: userCompany?.button_color }}>*</p>}
                              </div>
                            }
                            value={values.customFields[field.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                            options={
                              listItem.field_type === DROPDOW_OPTIONS.USER
                                ? optionsUser
                                : listItem.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                  ? optionsDepartment
                                  : listItem.field_type === DROPDOW_OPTIONS.OFFICE
                                    ? optionsOffice
                                    : listItem.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                      ? optionsJobTitle
                                      : listItem.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                        && optionsProjectGroup
                            }
                            onChange={(selectedOption) => setFieldValue(`customFields.${field.id}.${listItem.id}_copy_${copyIndex}`, selectedOption)}
                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                          />
                        </Row2>
                      )
                  ) : listItem.field_type === DATE_OPTIONS.DATE ? (
                    <Row2 style={{ marginTop: 17 }}>
                      <LabelWrapper>
                        <Label>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: 4, color: 'black' }}>
                            {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}{listItem.is_required && <p style={{ color: userCompany?.button_color }}>*</p>}
                          </div>
                        </Label>
                        <DatePicker
                          selected={values.customFields[field.id]?.[`${listItem.id}_copy_${copyIndex}`] || undefined}
                          onChange={(date) => setFieldValue(`customFields.${field.id}.${listItem.id}_copy_${copyIndex}`, date)}
                          dateFormat="yyyy-MM-dd"
                          autoComplete="off"
                          placeholderText="yyyy-MM-dd"
                          className={styleLIST.datePicker}
                          calendarClassName={styles.calendar}
                          withPortal
                        />
                      </LabelWrapper>
                    </Row2>
                  ) : listItem.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                    listItem.is_multiselect ? (
                      <Row2>
                        <CustomSelectMulti2
                          height='3rem'
                          label={
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                              {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                              {listItem.is_required && <p style={{ color: userCompany?.button_color }}>*</p>}
                            </div>
                          }
                          value={values.customFields[field.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                          options={optionsDropdownList}
                          onChange={(selectedOption) => setFieldValue(`customFields.${field.id}.${listItem.id}_copy_${copyIndex}`, selectedOption)}
                          placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                        />
                      </Row2>
                    ) : (
                      <Row2>
                        <CustomSelect2
                          height='3rem'
                          label={
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                              {listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                              {listItem.is_required && <p style={{ color: userCompany?.button_color }}>*</p>}
                            </div>
                          }
                          value={values.customFields[field.id]?.[`${listItem.id}_copy_${copyIndex}`]}
                          options={optionsDropdownList}
                          onChange={(selectedOption) => setFieldValue(`customFields.${field.id}.${listItem.id}_copy_${copyIndex}`, selectedOption)}
                          placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${listItem.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                        />
                      </Row2>
                    )
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
        <button type='button' style={{ backgroundColor: 'transparent', border: 'none', display: 'flex', alignSelf: 'flex-end', color: userCompany?.button_color, cursor: 'pointer' }} onClick={() => handleDuplicate(field.id)}>
          <LuPlusCircle size={30} />
        </button>
      </div>
    </div>
  );
                                    })}
                                    {taskData?.custom_fields?.filter((field: any) => field.is_output && field.field_type === EDITOR.EDITOR).map((field: any) => {
                                        const fieldName = field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name || 'N/A';

                                        return (
                                            <div key={field.id} style={{marginTop: 20, width: '80%'}}>
                                                <Label style={{textAlign: 'left', marginBottom: '0.5rem'}}>{fieldName}</Label>
                                                <WangEditorTask name={`customFields.${field.id}`} />
                                            </div>
                                        );
                                    })}
                                    <Button type="submit" appearance="primary" style={{marginTop: 20}}>{formatMessage({ id: 'task.save', defaultMessage: 'Save' })}</Button>

              </>)}
                        {activeTab === 'history' && (
                            <div>
                                {taskData?.histories?.map((history: any) => (
                                <div key={history.id} style={{margin: 20}}>
                                    <InfoSection>
                                    <UserInfo>
                                        <UserAvatar style={{background: color, alignSelf: 'flex-start'}}>
                                            <UserInitials>
                                                {history?.user?.fullname.split(' ').map((name: any) => name.charAt(0)).join('') || 'UN'} 
                                            </UserInitials>
                                                </UserAvatar>
                                            <div style={{flexDirection: 'column', marginLeft: 5, maxWidth: '80%'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                                <UserName>{history?.user?.fullname || 'Unknown'}</UserName>
                                                <p style={{ padding: 5 }}>{formatMessage({ id: 'task.has', defaultMessage: 'has' })}</p>
                                                <UserName>{history?.change_type}</UserName> 
                                                <p style={{padding: 5}}>{history?.created_at}</p>
                                            </div>
                                            {history && (
                                            <div style={{ paddingLeft: 5, color: 'grey', fontSize: 14, wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: intl.locale === 'sv' ? history.comment_sv : history.comment_en }} />
                                            )}
                                            </div>
                                        </UserInfo>                                                           
                                    </InfoSection>
                                        
                                </div>
                                ))}
                            </div>
                        )} 
                         </Form>
                     )}          
                </Formik>
                    </div>       
                    <div>
                        
                        <TaskType>{formatMessage({ id: 'task.taskType', defaultMessage: 'Task type' })}: {taskData?.task_type.name}</TaskType>
                    <DropdownStatus
                            label={'Status'}
                            value={selectedStatus}
                            options={optionsStatusEdit}
                            onChange={(selectedOption) => {
                                setSelectedStatus(selectedOption)
                                handleStatusChange(selectedOption.value)
                            }}
                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} status...`}
                        />      
                 
                        <InfoContainer>
                            <InfoButton onClick={()=>{setShowInfo(!showInfo)}}>
                                <InfoTitle>Information</InfoTitle>
                                {showInfo ? <RiArrowDropUpLine size={30} color='grey' /> : <RiArrowDropDownLine size={30} color='grey' />}
                            </InfoButton>
                            {showInfo && (
                                <>                                   
                                    {(taskData?.is_overdue || taskData?.is_archived) && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {taskData?.is_overdue && (
                                            <InfoSection style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center', marginTop: 15, marginBottom: 2 }}>
                                                <div style={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: '50%' }} />
                                                <p style={{ color: 'black', textTransform: 'uppercase', fontSize: 12 }}>{formatMessage({ id: 'task.overdue', defaultMessage: 'OVERDUE' })}</p>
                                            </InfoSection>)}

                                        {taskData?.is_archived && (
                                            <InfoSection style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center',  marginTop: 15, marginBottom: 2 }}>
                                                <div style={{ width: 10, height: 10, backgroundColor: '#926900', borderRadius: '50%' }} />
                                                <p style={{ color: 'black', textTransform: 'uppercase', fontSize: 12 }}>{formatMessage({ id: 'task.archived', defaultMessage: 'ARCHIVED' })}</p>
                                            </InfoSection>)}
                                    </div>)}

                                <InfoSection>
                                    <p style={{color: 'grey', fontWeight: '500'}}>{formatMessage({ id: 'task.assigne', defaultMessage: 'Assigne' })}</p>
                                    <UserInfo>
                                        <UserAvatarInfo style={{background: color}}>
                                            <UserInitialsInfo>
                                                {taskData?.assignee.fullname.split(' ').map((name: any) => name.charAt(0)).join('')}
                                            </UserInitialsInfo>
                                        </UserAvatarInfo>
                                        <UserNameInfo>{taskData?.assignee.fullname}</UserNameInfo>
                                    </UserInfo>
                                </InfoSection>
                                <InfoSection>
                                        <p style={{ color: 'grey', fontWeight: '500' }}>{formatMessage({ id: 'task.creator', defaultMessage: 'Created by: ' })}</p>
                                    <UserInfo>
                                        <UserAvatarInfo style={{background: color}}>
                                            <UserInitialsInfo>
                                                {taskData?.creator.fullname.split(' ').map((name: any) => name.charAt(0)).join('')}
                                            </UserInitialsInfo>
                                        </UserAvatarInfo>
                                        <UserNameInfo>{taskData?.creator?.fullname}</UserNameInfo>
                                        </UserInfo>
                                    </InfoSection>

                                    <InfoSection>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, gap: 5 }}>
                                        <p style={{color: 'grey', fontWeight: '500'}}>Status:</p>
                                        <Status style={{ backgroundColor: taskData?.status?.bg_color, color: taskData?.status?.font_color }}>{taskData?.status?.name}</Status> 
                                    </div>
                                    </InfoSection>
                            </>
                            )}
                        </InfoContainer>
                        <Dates>
                            {taskData?.updated_at && <p>{formatMessage({ id: 'task.updateAt', defaultMessage: 'Updated at: ' })} {taskData?.updated_at}</p>}
                            {taskData?.created_at && <p>{formatMessage({ id: 'task.createAT', defaultMessage: 'Created at: ' })} {taskData?.created_at}</p>}
                        </Dates>
                    </div>
                    
                </Content>
                <Footer>
                     {taskData?.completed_date && <p style={{ color: 'green' }}>{formatMessage({ id: 'task.completedDate', defaultMessage: 'Completed date:' })} {taskData?.completed_date}</p>}
                     {taskData?.due_date && <p style={{ color: 'red' }}>{formatMessage({ id: 'task.dueDate', defaultMessage: 'Due Date' })}: {taskData?.due_date}</p>}
                </Footer>
                <ButtonSecondary onClick={captureTaskPage}>
                    {formatMessage({ id: 'task.downloadPDF', defaultMessage: 'Download as PDF' })}
                </ButtonSecondary>
            </Container>
            {popUpMessage && <Toast message={formatMessage({ id: 'task.saved', defaultMessage: 'Saved!' })} />}
        </div>
    );
};


  /* 
       const captureTaskPage = async () => {
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const margin = 10;
        const maxLineWidth = pageWidth - margin * 2;

        const title = taskData?.title || '';
        const content = taskData?.content || '';
        const taskType = taskData?.task_type?.name || '';
        const logoUrl = userCompany?.logo?.url || '';

        const addTextContent = () => {
            const titleLines = pdf.splitTextToSize(`Title: ${title}`, maxLineWidth);
            const contentLines = pdf.splitTextToSize(`Content: ${content}`, maxLineWidth);

            let y = margin + 30; // Adjust starting position to account for logo
            pdf.text(titleLines, margin, y);
            y += titleLines.length * 10; // Adjust line height as needed
            pdf.text(contentLines, margin, y);

            // Add task type on the right side
            pdf.text(`Task type: ${taskType}`, pageWidth - margin - pdf.getTextWidth(`Task type: ${taskType}`), margin + 10);

            pdf.save('task-page.pdf');
        };

        // Add logo
        if (logoUrl) {
            const img = new Image();
            img.crossOrigin = 'Anonymous'; // Enable CORS
            img.src = logoUrl;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/png');
                pdf.addImage(dataURL, 'PNG', margin, margin, 50, 20); // Adjust size and position as needed
                addTextContent();
            };
            img.onerror = () => {
                console.error('Error loading logo image');
                addTextContent();
            };
        } else {
            addTextContent();
        }
    }; */

    /* const captureTaskPage = async () => {
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const sanitizeContent = (htmlContent: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        return doc.body.innerHTML || '';
    };

    const content =  sanitizeContent(taskData?.content) || '';
    const taskType = taskData?.task_type?.name || '';
    const logoUrl = userCompany?.logo?.url || '';


    // Process custom fields and categorize them into is_input and is_output
    const customFields = taskData?.custom_fields?.reduce((acc: any, field: any) => {
        const entityValue = taskData?.custom_field_entity_values?.find(
            (cf: any) => cf.custom_field_id === field.id
        )?.value;
        const fieldValue = taskData?.custom_field_values?.find(
            (cf: any) => cf.custom_field_id === field.id
        )?.value;

        const value = (() => {
            switch (field.field_type) {
                case DROPDOW_OPTIONS.USER:
                case DROPDOW_OPTIONS.DEPARTMENT:
                case DROPDOW_OPTIONS.OFFICE:
                case DROPDOW_OPTIONS.JOB_TITLE:
                case DROPDOW_OPTIONS.PROJECT_GROUP:
                    return entityValue
                        ? Array.isArray(entityValue)
                            ? entityValue.map((val: any) => val.name).join(', ')
                            : entityValue.name
                        : 'N/A';

                case TASK_FORM_OPTIONS.DROPDOWN:
                    return fieldValue
                        ? fieldValue
                              .map((val: any) =>
                                  taskData?.custom_fields
                                      ?.find((cf: any) => cf.id === field.id)
                                      ?.options?.find((option: any) => option.id === val.id)
                                      ?.translations?.find(
                                          (translation: any) => translation.language_code === intl.locale
                                      )?.translated_value
                              )
                              .join(', ')
                        : 'N/A';

                case INPUT_FILED.STRING:
                case INPUT_FILED.NUMBER:
                    return fieldValue || 'N/A';

                case DATE_OPTIONS.DATE:
                    return fieldValue?.date || 'N/A';

                case EDITOR.EDITOR:
                    return fieldValue || 'N/A';

                default:
                    return 'N/A';
            }
        })();

        if (field.is_input) {
            acc.is_input[field.id] = value;
        } else if (field.is_output) {
            acc.is_output[field.id] = value;
        }

        return acc;
    }, { is_input: {}, is_output: {} }) || {};

    const addTextContent = () => {
        const contentLines = pdf.splitTextToSize(content, maxLineWidth);

        let y = margin + 40; // Adjust starting position to account for logo
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'normal');
        pdf.text(contentLines, margin, y);

        y += contentLines.length * 10;

        const addCustomFields = (fields: any, title: string) => {
            if (Object.keys(fields).length > 0) {
                y += 15; // Add space before custom fields
                pdf.setFont('helvetica', 'bold');
                pdf.text(title, margin, y);
                y += 10;

                pdf.setFont('helvetica', 'normal');
                Object.entries(fields).forEach(([fieldId, value]) => {
                    // Convert fieldId to number for comparison with custom_field.id
                    const field = taskData?.custom_fields?.find(
                        (customField: any) => customField.id === Number(fieldId) // Ensure type match
                    );

                    // Extract the translated name if available
                    const fieldName = field?.translations?.find(
                        (translation: any) => translation.language_code === intl.locale
                    )?.translated_name;

                    // Split and render the field name and value
                    const displayValue = field.field_type === DATE_OPTIONS.DATE && value ? new Date(value as any).toLocaleDateString('en-CA') : value;
                    const fieldLines = pdf.splitTextToSize(`${fieldName}: ${displayValue}`, maxLineWidth);
                    pdf.text(fieldLines, margin, y);
                    y += fieldLines.length * 10; // Adjust line height
                });
            }
        };

        addCustomFields(customFields.is_input, 'Input Fields');
        addCustomFields(customFields.is_output, 'Output Fields');


        pdf.save('task-page.pdf');
    };

    // Add logo
    const img = new Image();
    img.crossOrigin = ''; // Enable CORS
    img.src = logoUrl;
    img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        pdf.addImage(dataURL, 'PNG', margin, margin, 50, 20); // Adjust size and position

        // Move task type to the right side at the top
        pdf.setFont('helvetica', 'italic');
        pdf.text(
            `${taskType}`,
            pageWidth - margin - pdf.getTextWidth(`${taskType}`),
            margin + 10
        );

        addTextContent();
    };
    img.onerror = () => {
        console.error('Error loading logo image');
        
        // Move task type to the right side at the top
        pdf.setFont('helvetica', 'italic');
        pdf.text(
            `${taskType}`,
            pageWidth - margin - pdf.getTextWidth(`${taskType}`),
            margin + 10
        );

        addTextContent();
    };
}; */
