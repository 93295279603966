import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px;
`;

export const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    gap: 20px;
`;

export const CalendarContainer = styled.div`
    max-width: 300px;
    height: 100%;
`;

export const ButtonTabs = styled.button<{ active: boolean, color: any }>`
  background-color: ${({ active, color }) => (active ? color : 'transparent')};
  color: ${({ active }) => (active ? 'white' : 'rgba(141, 141, 141, 1)')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 7px;
  font-weight: 400;
  height: 36px;
`;

export const ButtonFilter = styled.button<{ color: any, active?: boolean }>`
  display: flex;
  background-color: ${({ active, color }) => (active ? color : 'rgba(0, 0, 0, 0.09)')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 7px;
  height: 38px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
    &::-webkit-scrollbar {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Thead = styled.thead`
    background-color: #efefef;
    color: #000;
`

export const Tbody = styled.tbody`
    background-color: #fff;
`

export const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`

export const Th = styled.th`
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
`

export const Td = styled.td`
  vertical-align: middle;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`

export const TdButton = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
`
export const TdText = styled.td`
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
`


