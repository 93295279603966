import { get, update } from 'lodash';
import { apiSlice, convertToFormData } from './apiSlice';
import { upload } from '@testing-library/user-event/dist/upload';

export const taskApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTask: builder.query({
      query: ({ search = '', page = 1, overdue, archived, statusPaginated, personal }) => {
        const params = new URLSearchParams();
        if (search) {
          params.append('search', encodeURIComponent(search));
        }
        if (overdue) {
          params.append('overdue', overdue.toString());
        }
        if (archived) {
          params.append('archived', archived.toString());
        }
        if (personal) {
          params.append('personal', personal.toString());
        }

        if (statusPaginated.length > 0) {
          statusPaginated.forEach((status: any) => {
            params.append('status[]', status);
          });
        }
   
        params.append('page', page.toString());

        const queryString = params.toString().replace(/%5B%5D/g, '[]');

        return {
          url: `/api/v1/admin/tasks?${queryString}`,
          method: 'GET',
        };
      },
    }),
    getTaskStatuses: builder.query({
      query: () => ({
        url: `/api/v1/admin/tasks/statuses`,
        method: 'GET',
      }),
    }),
    createTask: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/tasks/`,
        method: 'POST',
        body: data,
      }),
      extraOptions: {
        useErrorHandling: false,
    },
    }),
    getOneTask: builder.query({
      query: ({id}) => ({
        url: `/api/v1/admin/tasks/${id}/`,
        method: 'GET',
      }),
    }),
    updateTask: builder.mutation({
      query: ({ data, id }) => ({
        url: `/api/v1/admin/tasks/${id}?_method=put`,
        method: 'POST',
        body: data,
      }),
      extraOptions: {
        useErrorHandling: false,
    },
    }),
    updateTaskOne: builder.mutation({
      query: ({ data, id }) => ({
        url: `/api/v1/admin/tasks/${id}/update/fields?_method=put`,
        method: 'PUT',
        body: data,
      }),
    }),
      deleteTask: builder.mutation({
        query: ({ id }) => ({
          url: `/api/v1/admin/tasks/${id}/`,
          method: 'DELETE',
      }),
    }),
    getAllTaskRegular: builder.query({
      query: ({ search = '', page = 1 }) => ({
        url: `/api/v1/tasks?search=${encodeURIComponent(search)}&page=${page}`,
        method: 'GET',
      }),
    }),
    downloadTask: builder.query({
      query: () => ({
        url: `/api/v1/admin/tasks/download`,
        method: 'GET',
        responseHandler: (response) => response.blob(), 
      }),
  }),
    getOneTaskRegular: builder.query({
      query: ({id}) => ({
        url: `/api/v1/tasks/${id}/`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllTaskQuery, useGetTaskStatusesQuery,
  useCreateTaskMutation, useGetOneTaskQuery,
  useUpdateTaskMutation, useUpdateTaskOneMutation,
  useDeleteTaskMutation, useGetAllTaskRegularQuery,
  useDownloadTaskQuery, useGetOneTaskRegularQuery,
} = taskApiSlice;
