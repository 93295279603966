import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { TaskGrid, TaskContainer, Entry, P, Header, Actions, IconButton, Button } from './TaskType.style';
import { LuFileEdit, LuTrash, LuPlus, LuCopyCheck } from 'react-icons/lu';
import { formatMessage } from '../../locale';
import { paths } from '../../config/paths';
import { Paper } from '../../ui-kit';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { HideOnMobile } from '../../components/BaseLayout/BaseLayout.styles';
import ButtonSecondary from '../../ui-kit/Button/ui/components/ButtonSecondary';
import { useGetAllTaskTypesQuery, useDeleteTaskTypeMutation } from '../../slices/taskTypeApiSlice';
import { TaskTypePermisson } from '../../enum/TaskPermisson/TaskTypePermisson';
import PaginableList from '../../ui-kit/PaginableListTaskType';
import CopyPopUp from './Components/CopyPopUp/CopyPopUp';




const FilterButtons: React.FC<{ user: any, draft: any, setDraft: any }> = ({ user, draft, setDraft}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', cursor: 'normal', marginBottom: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '266px', height: '36px', justifyContent: 'space-between', border: '0.063rem solid #D2D4D7', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)' }}>
          <Button active={draft === '0'} color={user} style={{ width: 144 }} onClick={() => { setDraft("0"); }}>{formatMessage({ id: 'task.active', defaultMessage: 'ACTIVE' })}</Button>
          <Button active={draft === '1'} color={user} style={{ width: 144 }} onClick={() => { setDraft("1"); }}>{formatMessage({ id: 'taskType.draft', defaultMessage: 'DRAFT' })}</Button>
        </div>
      </div>
  );
};


const TaskType: React.FC = () => {
  const navigate = useNavigate();
  const { intl } = useIntlContext();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];

  const [draft, setDraft] = useState<any>("0");

  const [currentPage, setCurrentPage] = useState(1);
  const [isCopyPopupOpen, setCopyPopupOpen] = useState(false);
  const [selectedTaskTypeId, setSelectedTaskTypeId] = useState<number | null>(null);
  const [deleteTaskType] = useDeleteTaskTypeMutation();

  const openModal = (id: number) => {
    setSelectedTaskTypeId(id);
    setCopyPopupOpen(true);
  };

  const closeCopyPopup = () => {
    setCopyPopupOpen(false);
    setSelectedTaskTypeId(null);
  };

  return (
    <PaginableList query={useGetAllTaskTypesQuery} currentPage={currentPage}  draft={draft} >
      {(taskTypes, isLoading, error, refetch) => {
        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTaskTypes', defaultMessage: 'Error while loading task types' })}</p></div>;

        const allData = taskTypes || [];

        async function handleDelete(id: number) {
          try {
            await deleteTaskType({ id }).unwrap();
            refetch();
          } catch (error) {
            console.error('Error deleting task type:', error);
          }
        }

        return (
          <>
             <FilterButtons user={userCompany?.button_color} draft={draft} setDraft={setDraft} />
            <TaskGrid>         
              {allData.map((item: any, index: number) => (
                <TaskContainer key={index}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                    <h3 style={{ textAlign: 'left', overflowWrap: 'break-word', wordWrap: 'break-word', maxWidth: 'calc(100% - 35%)' }}>
                      {item.name}
                    </h3>
                    <Actions>
                      {canDo(TaskTypePermisson.taskTypeUpdate) && (
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/taskType/edit/${item.id}`);
                          }}
                        >
                          <LuFileEdit size={16} />
                        </IconButton>
                      )}
                      {canDo(TaskTypePermisson.taskTypeCopyToSubsidiary) && (
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(item.id);
                          }}
                        >
                          <LuCopyCheck size={16} />
                        </IconButton>
                      )}
                      {canDo(TaskTypePermisson.taskTypeDelete) && (
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(item.id);
                          }}
                        >
                          <LuTrash size={16} />
                        </IconButton>
                      )}
                    </Actions>
                  </div>

                  <div>
                    <Entry>{item.description}</Entry>
                    <div style={{ position: 'absolute', bottom: '20px' }}>
                      {item.created_at && (
                        <P style={{ fontSize: 12 }}>
                          {formatMessage({ id: 'taskType.createdAt', defaultMessage: 'Created at: ' })}
                          {new Date(item.created_at).toLocaleDateString()}
                        </P>
                      )}
                      {item.updated_at && (
                        <P>
                          {formatMessage({ id: 'taskType.updatedAt', defaultMessage: 'Updated at: ' })}
                          {new Date(item.updated_at).toLocaleDateString()}
                        </P>
                      )}
                    </div>
                  </div>
                  </TaskContainer>
              ))}
             
            </TaskGrid>
            {isCopyPopupOpen && selectedTaskTypeId !== null && (
              <CopyPopUp
                Id={selectedTaskTypeId}
                onClose={closeCopyPopup}
                onCopySuccess={refetch}
              />
            )}
             {allData.length === 0 && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh', width: '100%' }}>
                <h1>{formatMessage({ id: 'taskType.noTaskTypes', defaultMessage: 'No courses available' })}</h1>
              </div>
              )}
            <Header>
              <ButtonSecondary style={{ marginTop: 20, alignSelf: 'flex-end' }} onClick={() => { navigate(paths.createTaskType) }}>
                <LuPlus size={20} />
                <HideOnMobile>
                  {formatMessage({ id: 'taskType.createNewTaskType', defaultMessage: 'Create new task type' })}
                </HideOnMobile>
              </ButtonSecondary>
            </Header>
          </>
        );
      }}
    </PaginableList>
  );
};

export default TaskType;