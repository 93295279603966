import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button } from './index';
import { SearchInputForm } from './Form/components/Input/SearchInput';
import { useIntlContext } from '../locale/IntlProviderWrapper';
import { formatMessage } from '../locale'

interface PaginableListProps<T> {
  my_page?: any;
  query: (args: { search: string; page: number }) => any; // The query hook with search and page parameters
  currentPage: number; // Current page number
  children: (
    items: T[],
    isLoading: boolean,
    error: FetchBaseQueryError | SerializedError | undefined,
    refetch: () => void
  ) => React.ReactNode; // Render prop
}

export const PaginableList = <T,>({
                                    query,
                                    currentPage,
                                    my_page,
                                    children,
}: PaginableListProps<T>) => {
  const { intl } = useIntlContext();
  const location = useLocation();
  const [page, setPage] = useState(currentPage);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); // Debounced search term
  const [items, setItems] = useState<T[]>([]);

  // Fetch data with searchValue and page
  const { data, error, isLoading, refetch } = query({ search: debouncedSearchTerm, page });

  useEffect(() => {
    refetch();
  }, [location.key, refetch]);

  // Refetch data when searchValue or page changes
  useEffect(() => {
    refetch();
  }, [debouncedSearchTerm, page, refetch]); // Track debouncedSearchTerm and page changes

  useEffect(() => {
    if (!data?.data) {
      setItems([]);
      return;
    }

    // Set items directly from the backend response
    setItems(data.data);
  }, [data]);

  // Handle search input changes with debounce
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Function to handle page change
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  // Function to handle search input change and reset page to 1
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setPage(1); // Reset page to 1 when search term changes
  };

  // Generate pagination buttons based on meta information
  const renderPaginationButtons = () => {
    if (!data?.meta) return null;
    const { current_page, last_page } = data.meta;

    const buttons = [];
    for (let i = 1; i <= last_page; i++) {
      buttons.push(
        <Button
          key={i}
          appearance={i === current_page ? 'secondary' : 'primary'}
          onClick={() => handlePageChange(i)}
          disabled={i === current_page}
          style={{ margin: '0 5px', padding: '5px 10px' }}
        >
          {i}
        </Button>
      );
    }

    return (
      <div style={{ marginTop: '20px' }}>
        {current_page > 1 && (
          <Button
            onClick={() => handlePageChange(current_page - 1)}
            style={{ margin: '0 5px', padding: '5px 10px' }}
          >
            {formatMessage({ id: 'previous', defaultMessage: 'Previous' })}
          </Button>
        )}
        {buttons}
        {current_page < last_page && (
          <Button
            onClick={() => handlePageChange(current_page + 1)}
            style={{ margin: '0 5px', padding: '5px 10px' }}
          >
            {formatMessage({ id: 'next', defaultMessage: 'Next' })}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div>
      {my_page ? (
        <div style={{position: 'absolute', top: 100}}>
        <SearchInputForm
          name="search"
          placeholder={formatMessage({ id: 'search', defaultMessage: 'Search...' })}
          label=""
          onChange={handleSearchChange}
          onSubmit={() => {}}
          initialValues={{ search: searchTerm }}
          />
        </div>
      ) : (
      <div>
      <SearchInputForm
        name="search"
        placeholder={formatMessage({ id: 'search', defaultMessage: 'Search...' })}
        label=""
        onChange={handleSearchChange}
        onSubmit={() => {}}
        initialValues={{ search: searchTerm }}
        />
      </div>
      )}
    
      {/* Render children with the fetched data */}
      {children(items, isLoading, error, refetch)}
      {items.length > 0 && renderPaginationButtons()}
    </div>
  );
};

export default PaginableList;
