import { apiSlice } from './apiSlice';

export const taskTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTaskTypes: builder.query({
      query: ({ search = '', page = 1, draft }) => {
        const params = new URLSearchParams();
        if (search) {
          params.append('search', encodeURIComponent(search));
        }
        if (draft) {
          params.append('draft', draft.toString());
        }
   
        params.append('page', page.toString());

        const queryString = params.toString().replace(/%5B%5D/g, '[]');

        return {
          url: `/api/v1/admin/tasks/types?${queryString}`,
          method: 'GET',
        };
      },
    }),
    createTaskType: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/tasks/types/`,
        method: 'POST',
        body: data,
      }),
    }),
    getOneTaskType: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/admin/tasks/types/${id}/`,
        method: 'GET',
      }),
    }),
    updateTaskType: builder.mutation({
      query: ({ data, id }) => ({
        url: `/api/v1/admin/tasks/types/${id}?_method=put`,
        method: 'POST',
        body: data,
      }),
    }),
      deleteTaskType: builder.mutation({
        query: ({ id }) => ({
          url: `/api/v1/admin/tasks/types/${id}/`,
          method: 'DELETE',
      }),
    }),
    getOptionValueCustomFields: builder.query({
      query: ({ field_type }) => ({
        url: `/api/v1/admin/tasks/options/`,
        method: 'GET',
        params: { field_type },
      }),
    }),
    updateTaskTypeStatus: builder.mutation({
      query: ({ id, status_id }) => ({
        url: `/api/v1/admin/tasks/${id}/update/status?_method=put`,
        method: 'POST',
        body: status_id,
      }),
    }),
    copyTaskTypeToOtherCompany: builder.mutation({
      query: ({ id, companyId }) => {
        return {
          url: `/api/v1/admin/tasks/types/${id}/companies/${companyId}/copy`,
          method: 'POST'
        };
      },
      extraOptions: {
        useErrorHandling: false,
    },
    }), 
    draftTaskType: builder.query({
      query: ({ id }) => ({
      url: `/api/v1/admin/tasks/types/${id}/draft`,
      method: 'GET',
      }),
    }),
    getRisks: builder.query({
      query: ({ custom_field_type }) => ({
        url: `/api/v1/admin/tasks/types/custom-fields`,
        method: 'GET',
        params: { custom_field_type },
      }),
    }),
    getCustomFieldDashboard: builder.query({
      query: ({ taskTypeId, customFieldId }) => ({
        url: `/api/v1/admin/tasks/types/${Number(taskTypeId)}/custom-field/${Number(customFieldId)}`,
        method: 'GET',
      }),
    }),
    dashboardSettings: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/tasks/types/dashboard/settings`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetAllTaskTypesQuery, useCreateTaskTypeMutation, useGetOneTaskTypeQuery, useUpdateTaskTypeMutation, useDeleteTaskTypeMutation, useGetOptionValueCustomFieldsQuery, useUpdateTaskTypeStatusMutation, useCopyTaskTypeToOtherCompanyMutation, useLazyDraftTaskTypeQuery, useGetRisksQuery, useGetCustomFieldDashboardQuery, useDashboardSettingsMutation} = taskTypeApiSlice;
